import { INotification } from '@models';
import { createAction, props } from '@ngrx/store';

export const NotificationsRequest = createAction(
  'NOTIFICATIONS_REQUEST',
  props<{ reset?: boolean }>()
);
export const NotificationsSuccess = createAction(
  'NOTIFICATIONS_SUCCESS',
  props<{
    notifications: INotification[];
    reset?: boolean;
    total: number;
    unread: number;
  }>()
);
export const NotificationsHeadRequest = createAction(
  'NOTIFICATIONS_HEAD_REQUEST'
);
export const NotificationsHeadSuccess = createAction(
  'NOTIFICATIONS_HEAD_SUCCESS',
  props<{
    total: number;
    unread: number;
  }>()
);
export const NotificationsError = createAction('NOTIFICATIONS_ERROR');

export const NotificationReadRequest = createAction(
  'NOTIFICATION_READ_REQUEST',
  props<{ notificationId: number; read: boolean }>()
);
export const NotificationReadSuccess = createAction(
  'NOTIFICATION_READ_SUCCESS',
  props<{ notification: INotification }>()
);
export const NotificationReadError = createAction(
  'NOTIFICATION_READ_ERROR',
  props<{ notificationId: number }>()
);
export const NotificationsReadRequest = createAction(
  'NOTIFICATIONS_READ_REQUEST'
);
export const NotificationsReadSuccess = createAction(
  'NOTIFICATIONS_READ_SUCCESS'
);
export const NotificationsReadError = createAction('NOTIFICATIONS_READ_ERROR');
export const NotificationDeleteRequest = createAction(
  'NOTIFICATION_DELETE_REQUEST',
  props<{ notificationId: number }>()
);
export const NotificationDeleteSuccess = createAction(
  'NOTIFICATION_DELETE_SUCCESS',
  props<{ notificationId: number }>()
);
export const NotificationDeleteError = createAction(
  'NOTIFICATION_DELETE_ERROR',
  props<{ notificationId: number }>()
);
export const NotificationsDeleteRequest = createAction(
  'NOTIFICATIONS_DELETE_REQUEST'
);
export const NotificationsDeleteSuccess = createAction(
  'NOTIFICATIONS_DELETE_SUCCESS'
);
export const NotificationsDeleteError = createAction(
  'NOTIFICATIONS_DELETE_ERROR'
);

export type ActionsUnion =
  | typeof NotificationsRequest
  | typeof NotificationsSuccess
  | typeof NotificationsError
  | typeof NotificationReadRequest
  | typeof NotificationReadSuccess
  | typeof NotificationReadError
  | typeof NotificationsReadRequest
  | typeof NotificationsReadSuccess
  | typeof NotificationsReadError
  | typeof NotificationDeleteRequest
  | typeof NotificationDeleteSuccess
  | typeof NotificationDeleteError
  | typeof NotificationsDeleteRequest
  | typeof NotificationsDeleteSuccess
  | typeof NotificationsDeleteError
  | typeof NotificationsHeadRequest
  | typeof NotificationsHeadSuccess;
